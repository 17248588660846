import React, { useMemo } from "react"

import { useCustomerRecover } from "../../../hooks/useCustomer"

export const withForgotForm = Component =>
  React.memo(({ name = "ForgotForm", ...props }) => {
    const { recoverCustomer, data, setData, loading, errors } = useCustomerRecover()

    const handleSubmit = async event => {
      event.preventDefault()
      await recoverCustomer(data?.email)
    }

    const handleChange = ({ target: { type, name, value, checked } }) => {
      setData(prevState => ({
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
      }))
    }

    Component.displayName = name
    return useMemo(
      () => <Component {...props} data={data} handleChange={handleChange} handleSubmit={handleSubmit} loading={loading} errors={errors} />,
      [data, loading, errors]
    )
  })
