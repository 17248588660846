import React from "react"

import { withForgot } from "./withForgot"
import { ForgotForm } from "./ForgotForm"
import { Container, Frame, Title } from "../../Form/Form.styled"

export const Forgot = withForgot(
  ({ title, content }): JSX.Element => (
    <Container width={`md`}>
      <Frame>
        <Title description={content}>{title}</Title>
        <ForgotForm content={content} />
      </Frame>
    </Container>
  )
)
