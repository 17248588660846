import React from "react"

import { withForgotForm } from "./withForgotForm"
import { Form, Description, Field, Button, ButtonWrapper } from "../../Form/Form.styled"

export const ForgotForm = withForgotForm(
  ({ data, handleChange, handleSubmit, loading, errors, content }): JSX.Element => (
    <Form onSubmit={handleSubmit}>
      {content && errors?.length < 1 ? <Description>{content}</Description> : null}
      {errors?.length > 0 && (
        <Description>
          <ul>
            {errors?.map((error, index) => (
              <li key={index}>
                <p>{error?.message}</p>
              </li>
            ))}
          </ul>
        </Description>
      )}
      <Field
        name={"email"}
        type={"email"}
        placeholder={"Email address"}
        value={data?.email}
        onChange={handleChange}
        required={data?.email?.length > 0}
        autoFocus
      />
      <ButtonWrapper center={true}>
        <Button type="submit" center={true} size={`large`} colour={`dark`} theme={`primary`} loading={loading} disabled={data?.email?.length < 6}>
          Send password link
        </Button>
      </ButtonWrapper>
    </Form>
  )
)
